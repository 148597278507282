export function useConfirmation({
    title,
    description,
    confirmLabel = 'Confirm',
}: {
    title: MaybeRef<string>
    description?: MaybeRef<string>
    confirmLabel?: MaybeRef<string>
}) {
    const open = ref(false)

    let confirmCallback: (...args: any) => any

    const modalProps = computed(() => ({
        open: toValue(open),
        title: toValue(title),
        description: toValue(description),
        confirmLabel: toValue(confirmLabel),
        onClose: () => (open.value = false),
        onConfirm: () => {
            confirmCallback()
        },
    }))

    function confirmable(callback: (...args: any) => any) {
        confirmCallback = () => {
            open.value = false
            return callback()
        }

        open.value = true
    }

    return {
        modalProps,
        confirmable,
    }
}
